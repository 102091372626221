/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import voltoSubblocks from 'volto-subblocks';
import voltoFormBlock from 'volto-form-block';
import voltoFrontend from 'volto-frontend';
import eeacmsvoltoResizeHelper from '@eeacms/volto-resize-helper';
import eeacmsvoltoBlockStyle from '@eeacms/volto-block-style';
import voltoDropdownmenu from 'volto-dropdownmenu';

const addonsInfo = [{"modulePath":"/build/volto/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/build/volto/node_modules/@plone/volto/packages/volto-slate/package.json","version":"16.26.0","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"volto-subblocks","version":"2.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/volto/node_modules/volto-subblocks/src","packageJson":"/build/volto/node_modules/volto-subblocks/package.json","addons":[]},{"modulePath":"/build/volto/src/addons/volto-form-block/src","packageJson":"/build/volto/src/addons/volto-form-block/package.json","version":"2.6.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"volto-form-block","addons":["volto-subblocks"]},{"name":"volto-frontend","version":"0.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/volto/src/addons/volto-frontend/src","packageJson":"/build/volto/src/addons/volto-frontend/package.json","addons":[]},{"name":"@eeacms/volto-resize-helper","version":"2.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/volto/node_modules/@eeacms/volto-resize-helper/src","packageJson":"/build/volto/node_modules/@eeacms/volto-resize-helper/package.json","addons":[]},{"name":"@eeacms/volto-block-style","version":"7.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/build/volto/node_modules/@eeacms/volto-block-style/src","packageJson":"/build/volto/node_modules/@eeacms/volto-block-style/package.json","addons":["@eeacms/volto-resize-helper"]},{"modulePath":"/build/volto/src/addons/volto-dropdownmenu/src","packageJson":"/build/volto/src/addons/volto-dropdownmenu/package.json","version":"2.4.3","isPublishedPackage":false,"isRegisteredAddon":true,"name":"volto-dropdownmenu","addons":[]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, voltoSubblocks, voltoFormBlock, voltoFrontend, eeacmsvoltoResizeHelper, eeacmsvoltoBlockStyle, voltoDropdownmenu];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
